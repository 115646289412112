<template>
    <v-tooltip bottom :disabled="hideTooltip" open-delay="200">
        <template v-slot:activator="{ on }">
            <span v-on="on">
                <span v-if="!addGst">{{ value | currency(precision) }}</span>
                <span v-if="addGst">{{ (value * 1.1) | currency(precision) }}</span>
            </span>
        </template>
        ${{ value }}<span v-if="addGst"> excluding GST</span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'Currency',
    filters: {
        currency: function (value,precision) {
            if (value) {
              // Convert the number to the same precision as passed in the method.
              const formattedNumber = parseFloat(value).toFixed(precision);

              // Split the number into integer and decimal parts.
              const [integerPart, decimalPart = ''] = formattedNumber.split('.');

              // Add commas as thousand separators to the integer part.
              const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

              // Combine the integer part with the formatted decimal part.
              let formattedDecimal = decimalPart === '' ? '' : `.${decimalPart.replace(/0+$/, '')}`;

              //add any missing zero at the end to fill to 2 decimal places
              if(formattedDecimal.length < 3){
                if(formattedDecimal.length < 2){
                  formattedDecimal = formattedDecimal+'0';
                }
                formattedDecimal = formattedDecimal+'0';
              }

              // Add the dollar sign and return the formatted number.
              return `$${numberWithCommas}${formattedDecimal}`;

              // var n = 1.245000
              // var noZeroes = n.toString() // "1.245"
                // return (
                //     '$' +
                //     value
                //         .toFixed(precision)
                //         .toString()
                //         .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                // );
            }
            return '$0.00';
        },
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        addGst: {
            type: Boolean,
            default: false,
        },
        hideTooltip: {
            type: Boolean,
            default: false,
        },
      /**
       * How many decimal points to show (where applicable)
       */
        precision:{
            type: Number,
            default: 2,
        }
    },
};
</script>
