<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
      valid ({{valid}}) check is out
        <template v-slot:activator="{ on, attrs }">
          <v-form v-model="valid" ref="dateTimeForm" v-on:submit.prevent="">
            <v-text-field
                v-model="dateText"
                ref="date"
                :label="label"
                append-icon="mdi-calendar"
                outlined
                :dense="dense"
                :clearable="clearable"
                :hide-details="hideDetails"
                :hint="hint"
                :persistent-hint="persistentHint"
                v-bind="attrs"
                v-on="on"
                @click:append="menu = !menu"
                @click:clear="clear"
                :disabled="loading"
                :loading="loading"
                :rules="rules"
            ></v-text-field>
          </v-form>
        </template>
        <v-date-picker
            ref="picker"
            v-model="date"
            v-if="!loading"
            no-title
            scrollable
            @click:date="$refs.menu.save(date)"
        >
            <v-btn v-if="clearable" text block @click="clear">Clear</v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
const DateRegex = /^(\d{4}-(?:0[1-9]|1[0-2])-+(?:0[1-9]|[1-2][0-9]|3[0-1]))$/i;

export default {
    name: 'DatePicker',
    data: () => ({
        date: null,
        menu: false,
        dateText: '',
        rules: [
            (v) =>
                !v ||
                DateRegex.test(v) ||
                'Enter date in YYYY-MM-DD',
        ],
        valid: null,
      lastValidWatchDateText:null,
    }),
    props: {
        value: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: 'Date',
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        persistentHint: {
            type: Boolean,
            default: false,
        },
        hint: {
            type: String,
            default: null,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        futureOnly: {
            type: Boolean,
            default: false,
        },
        activePicker:{
            type: String,
            default:null,
        },
        rulesIn:{
          type: Array,
          default: function( ){
            return [];
          }
        }
    },
    methods: {
        clear: function () {
            this.date = null;
            this.dateText = '';
        },
    },

    watch: {
        date: function (value) {
            if (!!value && value !== this.dateText){
              this.dateText = value;
            }
        },
        valid: function (value) {
          this.lastValidWatchDateText = this.dateText;
          if(!value){
            this.$emit('input', null);
          }else{
            this.$emit('input', this.dateText);
          }
        },

        value: function (value) {
          //Useful is the value is set in the parent. We copy over to the dateText input.
          if(value != null || (this.dateText != this.lastValidWatchDateText)){
            this.dateText = value;
          }
        },
        dateText: function (value) {
            let date =
                DateRegex.test(
                    value
                )
                    ? value
                    : null;
            this.date = date;
        },
        menu (val) {
          val && setTimeout(() => (this.$refs.picker.activePicker = this.activePicker))
        },
    },

    created() {
        if (this.required) this.rules.push((v) => !!v || 'Required');
        if (this.futureOnly)
            this.rules.push(
                (v) =>
                    (DateRegex.test(
                        v
                    )
                        ? new Date(v).getDate() > new Date().getDate()
                        : true) || 'Must be in the future'
            );
        if(this.rulesIn.length > 0){
            this.rules = [...this.rules,...this.rulesIn];
        }
        if (this.value) {
            this.dateText = this.value;
        }
    },
};
</script>
